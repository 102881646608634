import { useState, useEffect } from "react";
import { ReactComponent as Video } from "../images/video.svg";
import { ReactComponent as Chat } from "../images/Chat.svg";
import { ReactComponent as Wellness } from "../images/Wellness.svg";
import { ReactComponent as Rx } from "../images/Rx.svg";
import { ReactComponent as Meeting } from "../images/schedule-advisor-meeting.svg";
import { ReactComponent as Enroll } from "../images/Enroll.svg";
import { ReactComponent as Telemed } from "../images/telemedicine.svg";
import { ReactComponent as OnlineSupport } from "../images/online-support.svg";
import { ReactComponent as MentalHealth } from "../images/mental-health.svg";
import { ReactComponent as Location } from "../images/find-a-provider.svg";
import { ReactComponent as BenefitInfo } from "../images/Benefit-Info.svg";
import { ReactComponent as MedicalConcierge } from "../images/Medical-Concierge.svg";
import { ReactComponent as Retirement } from "../images/401k.svg";
import { ReactComponent as Payroll } from "../images/Payroll.svg";
import { ReactComponent as ContactSupport } from "../images/Contact-Support.svg";
import { ReactComponent as Webinar } from "../images/Webinar.svg";
import { ReactComponent as Legal } from "../images/Legal.svg";
import { ReactComponent as IdentityProtection } from "../images/ID-Protection.svg";
import { ReactComponent as PetInsurance } from "../images/Pet-Insurance.svg";
import { ReactComponent as Instructions } from "../images/Instructions-icon.svg";
import SvgIcon from "@material-ui/core/SvgIcon";
import { Link, Typography } from "@material-ui/core";
import { isAndroid } from "react-device-detect";
import { IMobileDataLink } from "../models";
import { getCorrectBitlyLink } from "../fetch";
import { InstallPrompt } from "../components";

export enum EMobileAppLinkTypeEnums {
  INSTRUCTIONS = "Instructions",
  CALENDAR = "Calendar",
  TALK_TO_ADVISOR = "TalkToAdvisor",
  BENEFITS_INFO = "BenefitsInfo",
  BENEFITS_INFO_MULTILINGUAL = "BenefitsInfoMultilingual",
  MEDIA = "Media",
  MEDIA_MULTILINGUAL = "MediaMultilingual",
  CHAT = "Chat",
  FIND_A_DOCTOR = "FindADoctor",
  ENROLL_In_BENEFITS = "EnrollInBenefits",
  ENROLL_In_BENEFITS_MULTILINGUAL = "EnrollInBenefitsMultilingual",
  VIRTUAL_DOCTOR_VISIT = "VirtualDoctorVisit",
  PRESCRIPTION_DISCOUNTS = "PrescriptionDiscounts",
  WELLNESS_INCENTIVES = "WellnessIncentives",
  MENTAL_HEALTH = "MentalHealth",
  SPEAK_TO_CONCIERGE = "SpeakToConcierge",
  RETIREMENT = "Retirement",
  PAYROLL = "Payroll",
  SCREEN_SHARE = "ScreenShare",
  WEBINAR = "Webinar",
  WEBINAR_MULTILINGUAL = "WebinarMultilingual",
  LEGAL = "Legal",
  IDENTITY_PROTECTION = "IdentityProtection",
  PET_INSURANCE = "PetInsurance",
}

const icons = [
  {
    label: "Download Help",
    type: EMobileAppLinkTypeEnums.INSTRUCTIONS,
    icon: <Instructions />,
  },
  {
    label: "Schedule Meeting",
    type: EMobileAppLinkTypeEnums.CALENDAR,
    icon: <Meeting />,
  },
  {
    label: "Contact Support",
    type: EMobileAppLinkTypeEnums.TALK_TO_ADVISOR,
    icon: <ContactSupport />,
  },
  {
    label: "Benefit Info",
    type: EMobileAppLinkTypeEnums.BENEFITS_INFO,
    icon: <BenefitInfo />,
  },
  {
    label: "Benefit Guide - Multilingual",
    type: EMobileAppLinkTypeEnums.BENEFITS_INFO_MULTILINGUAL,
    icon: <BenefitInfo />,
  },
  {
    label: "Video",
    type: EMobileAppLinkTypeEnums.MEDIA,
    icon: <Video />,
  },
  {
    label: "Video - Multilingual",
    type: EMobileAppLinkTypeEnums.MEDIA_MULTILINGUAL,
    icon: <Video />,
  },
  {
    label: "Chat",
    type: EMobileAppLinkTypeEnums.CHAT,
    icon: <Chat />,
  },
  {
    label: "Find A Provider",
    type: EMobileAppLinkTypeEnums.FIND_A_DOCTOR,
    icon: <Location />,
  },
  {
    label: "Enroll Online",
    type: EMobileAppLinkTypeEnums.ENROLL_In_BENEFITS,
    icon: <Enroll />,
  },
  {
    label: "Enroll Online  - Multilingual",
    type: EMobileAppLinkTypeEnums.ENROLL_In_BENEFITS_MULTILINGUAL,
    icon: <Enroll />,
  },
  {
    label: "Telemedicine",
    type: EMobileAppLinkTypeEnums.VIRTUAL_DOCTOR_VISIT,
    icon: <Telemed />,
  },
  {
    label: "Rx",
    type: EMobileAppLinkTypeEnums.PRESCRIPTION_DISCOUNTS,
    icon: <Rx />,
  },
  {
    label: "Wellness",
    type: EMobileAppLinkTypeEnums.WELLNESS_INCENTIVES,
    icon: <Wellness />,
  },
  {
    label: "Mental Health",
    type: EMobileAppLinkTypeEnums.MENTAL_HEALTH,
    icon: <MentalHealth />,
  },
  {
    label: "Medical Concierge",
    type: EMobileAppLinkTypeEnums.SPEAK_TO_CONCIERGE,
    icon: <MedicalConcierge />,
  },
  {
    label: "401K",
    type: EMobileAppLinkTypeEnums.RETIREMENT,
    icon: <Retirement />,
  },
  {
    label: "Payroll",
    type: EMobileAppLinkTypeEnums.PAYROLL,
    icon: <Payroll />,
  },
  {
    label: "Screen Share",
    type: EMobileAppLinkTypeEnums.SCREEN_SHARE,
    icon: <OnlineSupport />,
  },
  {
    label: "Webinar",
    type: EMobileAppLinkTypeEnums.WEBINAR,
    icon: <Webinar />,
  },
  {
    label: "Webinar - Multilingual",
    type: EMobileAppLinkTypeEnums.WEBINAR_MULTILINGUAL,
    icon: <Webinar />,
  },
  {
    label: "Legal",
    type: EMobileAppLinkTypeEnums.LEGAL,
    icon: <Legal />,
  },
  {
    label: "Identity Protection",
    type: EMobileAppLinkTypeEnums.IDENTITY_PROTECTION,
    icon: <IdentityProtection />,
  },
  {
    label: "Pet Insurance",
    type: EMobileAppLinkTypeEnums.PET_INSURANCE,
    icon: <PetInsurance />,
  },
];

const ExternalLink = ({ children, title, link, linkClass }: any) => {
  const [correctLink, setCorrectLink] = useState<string>(link.link);
  const fetchLink = async () => {
    try {
      // hit our api endpoint to unshorten the bit.ly link and give use the actual link
      // so our links work
      const res = await getCorrectBitlyLink(link.link);
      // for android pwa users, if a pdf returns wrap it in google docs
      if (res.endsWith(".pdf") && isAndroid) {
        setCorrectLink(`https://docs.google.com/viewer?url=${res}`);
      } else {
        setCorrectLink(res);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (link && link.link && link.link.includes("bit.ly")) {
      fetchLink();
    }
  }, []);

  return (
    <Link
      href={correctLink}
      rel="noopener noreferrer"
      target="_blank"
      className={linkClass}
      title={title}
    >
      {children}
    </Link>
  );
};

const InternalModalLink = ({
  children,
  title,
  linkClass,
  mobileData,
  name,
}: any) => {
  const [openModal, setOpenModal] = useState(false);
  return (
    <>
      <div
        onClick={(e) => {
          setOpenModal(true);
        }}
        className={linkClass}
        title={title}
      >
        {children}
      </div>
      <InstallPrompt
        name={name}
        logoUrl={
          (mobileData &&
            (mobileData.images.homeLogoUrl || mobileData.images.appLogoUrl)) ||
          ""
        }
        open={openModal}
        setOpen={setOpenModal}
        backgroundColor={mobileData.hexColor}
        autoClose={false}
      />
    </>
  );
};

export const getMobileAppLink = (
  name: string,
  mobileData: any,
  link: IMobileDataLink,
  linkClass: string,
  iconClass: string,
  color: string,
  nameClass: string
) => {
  const selectedIcon = icons.find((i) => i.type === link.type);
  if (selectedIcon) {
    if (link.type === "Instructions") {
      return (
        <>
          <InternalModalLink
            title={link.name}
            linkClass={linkClass}
            name={name}
            mobileData={mobileData}
          >
            <SvgIcon
              className={iconClass}
              viewBox="0 0 256 256"
              style={{
                color,
              }}
            >
              {selectedIcon.icon}
            </SvgIcon>
          </InternalModalLink>
          <Typography
            align="center"
            style={{ color: mobileData.textHexColor }}
            className={nameClass}
          >
            {link.name || selectedIcon.label}
          </Typography>
        </>
      );
    } else {
      return (
        <>
          <ExternalLink link={link} title={link.name} linkClass={linkClass}>
            <SvgIcon
              className={iconClass}
              viewBox="0 0 256 256"
              style={{
                color,
              }}
            >
              {selectedIcon.icon}
            </SvgIcon>
          </ExternalLink>
          <Typography
            align="center"
            style={{ color: mobileData.textHexColor }}
            className={nameClass}
          >
            {link.name || selectedIcon.label}
          </Typography>
        </>
      );
    }
  }
  return null;
};
